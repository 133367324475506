@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url('../assets/Lato/Lato-ThinItalic.ttf');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('../assets/Lato/Lato-LightItalic.ttf');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/Lato/Lato-Italic.ttf');
}


@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('../assets/Lato/Lato-BoldItalic.ttf');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('../assets/Lato/Lato-BlackItalic.ttf');
}

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('../assets/Lato/Lato-Thin.ttf');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../assets/Lato/Lato-Light.ttf');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/Lato/Lato-Regular.ttf');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../assets/Lato/Lato-Bold.ttf');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../assets/Lato/Lato-Black.ttf');
}