@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url('../assets/OpenSans/OpenSans-LightItalic.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url('../assets/OpenSans/OpenSans-Italic.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url('../assets/OpenSans/OpenSans-MediumItalic.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url('../assets/OpenSans/OpenSans-SemiBoldItalic.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url('../assets/OpenSans/OpenSans-BoldItalic.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url('../assets/OpenSans/OpenSans-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url('../assets/OpenSans/OpenSans-Light.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url('../assets/OpenSans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url('../assets/OpenSans/OpenSans-Medium.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url('../assets/OpenSans/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url('../assets/OpenSans/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url('../assets/OpenSans/OpenSans-ExtraBold.ttf');
}